import React from 'react';
import useSearch from '../../hooks/useSearch';

function CityCard({ id, img, city, places }) {
  const [handleClick] = useSearch(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    city.toLowerCase()
  );

  return (
    <div onClick={() => handleClick()} className="city-card" key={id}>
      <div className="city-card__image">
        <div className="thumbnail" style={{ backgroundImage: `url(${img})` }} />
      </div>
      <div className="city-card-link">
        <div className="city-card__text">
          <h3 className="city-card__text--title">{city}</h3>
          <p className="city-card__text--description">
            Go and explore <br />
            <span>{places}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CityCard;
